import * as React from "react";
import useSound from "use-sound";
import Layout from "../../../components/layout/Layout.component";
import Header from "../../../components/header/Header.component";
import "./Piano.style.scss";

function Key({ info, selectedNodes, className = "key", url }) {
  let selected = "false";
  const [play] = useSound(url, {
    interrupt: true,
  });

  if (selectedNodes.includes(info.key)) {
    selected = "true";
    play();
  } else {
    selected = "false";
  }
  return (
    <div selectednode={selected} className={className}>
      {info.key}
    </div>
  );
}

function Keyboard() {
  const selectedNodes = new Set();

  const nodesList = [
    {
      id: 1,
      node: "C",
      key: "Z",
      url: "/piano-sound/key01.mp3",
    },
    {
      id: 2,
      node: "D",
      key: "X",
      url: "/piano-sound/key02.mp3",
    },
    {
      id: 3,
      node: "E",
      key: "C",
      url: "/piano-sound/key03.mp3",
    },
    {
      id: 4,
      node: "F",
      key: "V",
      url: "/piano-sound/key04.mp3",
    },
    {
      id: 5,
      node: "G",
      key: "B",
      url: "/piano-sound/key05.mp3",
    },
    {
      id: 6,
      node: "A",
      key: "N",
      url: "/piano-sound/key06.mp3",
    },
    {
      id: 7,
      node: "B",
      key: "M",
      url: "/piano-sound/key07.mp3",
    },
    {
      id: 8,
      node: "C#",
      key: "S",
      url: "/piano-sound/key08.mp3",
    },
    {
      id: 9,
      node: "D#",
      key: "D",
      url: "/piano-sound/key09.mp3",
    },
    {
      id: 10,
      node: "",
      key: "",
      url: "",
    },
    {
      id: 11,
      node: "F#",
      key: "G",
      url: "/piano-sound/key10.mp3",
    },
    {
      id: 12,
      node: "G#",
      key: "H",
      url: "/piano-sound/key11.mp3",
    },
    {
      id: 13,
      node: "A#",
      key: "J",
      url: "/piano-sound/key12.mp3",
    },
    {
      id: 14,
      node: "",
      key: "",
      url: "",
    },
  ];

  const upperNodes = [];
  const lowerNodes = [];

  nodesList.forEach((node, index) =>
    index > 6 ? upperNodes.push(node) : lowerNodes.push(node)
  );

  const [arraySelectedNodes, setArraySelectedNodes] = React.useState([]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keydown", (e) => {
        if (!selectedNodes.has(e.key.toUpperCase())) {
          selectedNodes.add(e.key.toUpperCase());
          setArraySelectedNodes(Array.from(selectedNodes));
        }
      });
      window.addEventListener("keyup", (e) => {
        selectedNodes.delete(e.key.toUpperCase());
        setArraySelectedNodes(Array.from(selectedNodes));
      });
    }
  }, []);

  return (
    <div className="piano">
      <div className="row">
        {upperNodes.map((node) => (
          <Key
            info={node}
            selectedNodes={arraySelectedNodes}
            key={node.id}
            url={node.url}
            className="key_black"
          />
        ))}
      </div>
      <div className="row">
        {lowerNodes.map((node) => (
          <Key
            info={node}
            selectedNodes={arraySelectedNodes}
            key={node.id}
            url={node.url}
          />
        ))}
      </div>
    </div>
  );
}

const Piano = () => {
  const page = {
    introduction: "Piano",
    header: "Play something amazing!",
    description: "There's a slight chance I got the keys wrong 😃",
  };
  return (
    <Layout>
      <Header page={page} />
      <Keyboard />
    </Layout>
  );
};
export default Piano;

export function Head() {
  return <title>Piano</title>;
}
